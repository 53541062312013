import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

const FurrFitPage = () => (
  <Layout>
    <SEO title="Home" />

    <div id="furrfit-hero" className="banner">
      <div className="container">
        <div className="row">
          <div className="banner-wrapper">
            <div className="col s12 m12 l12">
              <img src="/images/furrfit/hero.png" alt="FurrFit" />
              <div className="bannertext">
                <h1 className="pet-purple-text">FurrFit</h1>
                <h2 className="white-text">Not just tracking pet fitness <br /> but building a stronger bond</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="furrfit" className="content">
      <div id="section-1" className="service row">
        <div className="section-head">
          <div className="container">
            <h5>with FurrFit, your dog is</h5>
            <h3 className="pet-purple-text">always close</h3>
          </div>
        </div>
        <div className="section-content">
          <div className="container">
            <div className="section-content-wrapper flex-dir-col-rev">
              <div className="section-l align-items-flex-start">
                <ul className="section-desc fa-ul">
                  <li>
                    <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#403b87" size="1x" /></span>
                      <span className="desc-label">Get regular location updates</span>
                      <br />
                      so you always know where your dog is.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#403b87" size="1x" /></span>
                      <span className="desc-label">Set a Geo-Fence</span>
                      <br />
                      so your dog never goes too far.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="section-r">
                <div className="service-img-wrapper">
                  <img src="/images/furrfit/sec1.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="section-2" className="service row">
        <div className="section-head">
          <div className="container">
            <h5>with FurrFit, your dog is</h5>
            <h3 className="pet-purple-text">always fit</h3>
          </div>
        </div>
        <div className="section-content">
          <div className="container">
            <div className="section-content-wrapper flex-dir-col">
              <div className="section-l">
                <div className="service-img-wrapper">
                  <img src="/images/furrfit/sec2.svg" className="mt-10" alt="" />
                </div> 
              </div>
              <div className="section-r align-items-flex-start">
                <ul className="section-desc fa-ul">
                  <li>
                    <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#403b87" size="1x" /></span>
                      <span className="desc-label">Track Pet fitness</span>
                      <br />
                      so you can get reports of your dog’s fitness and set activity goals.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#403b87" size="1x" /></span>
                      <span className="desc-label">Monitor health recovery</span>
                      <br />
                      so you can track your dog’s illness recovery progress.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="furrfit-banner" className="banner">
        <div className="container">
          <div className="row">
            <div className="banner-wrapper">
              <div className="col s12 m12 l12">
                <img src="/images/furrfit/readdog.png" alt="" />
                <div className="bannertext">
                  <h2 className="white-text">Your dog can’t speak<br />but says a lot</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="section-3" className="service row">
        <div className="section-head">
          <div className="container">
            <h5>with FurrFit, you can</h5>
            <h3 className="pet-purple-text">read dog</h3>
          </div>
        </div>
        <div className="section-content">
          <div className="container">
            <div className="section-content-wrapper flex-dir-col">
              <div className="section-l">
                <div className="service-img-wrapper">
                  <img src="/images/furrfit/sec3.svg" className="mt-10" alt="" />
                </div>
              </div>
              <div className="section-r align-items-flex-start">
                <ul className="section-desc fa-ul">
                  <li>
                    <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#403b87" size="1x" /></span>
                      <span className="desc-label">Get smart illness alerts</span>
                      <br />
                      so you can detect early signs of diseases
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#403b87" size="1x" /></span>
                      <span className="desc-label">Smart anomaly detection</span>
                      <br />
                      so you can monitor anomalies in your dog’s activity using Artificial Intelligence.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="section-4" className="service row">
        <div className="section-head">
          <div className="container">
            <h5>FurrFit gives you more reasons to</h5>
            <h3 className="pet-purple-text">stay relaxed</h3>
          </div>
        </div>
        <div className="section-content">
          <div className="container">
            <div className="section-content-wrapper">
              <div className="features col">
                <div className="feature col md4 lg4 sm12">
                  <div className="feature-bg">
                    <img src="/images/furrfit/battery.svg" alt="7x Battery Life" />
                  </div>
                  <p className="desc-label">14-day Battery Life</p>
                </div>
                <div className="feature col md4 lg4 sm12">
                  <div className="feature-bg">
                    <img src="/images/furrfit/weather.svg" alt="Weather Proof" />
                  </div>
                  <p className="desc-label">Weather Proof</p>
                </div>
                <div className="feature col md4 lg4 sm12">
                  <div className="feature-bg">
                    <img src="/images/furrfit/lightncompact.svg" alt="Light and Compact" />
                  </div>
                  <p className="desc-label">Light and Compact</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="section-5" className="service row">
        <div className="section-head">
          <div className="container">
            <h4>We are constantly improving FurrFit.</h4>
            <p>Take <a href="!#" className="pet-purple-text">this small survey</a> and let us know your thoughts.</p>
          </div>
        </div>
        <a href="!#" className="survey-go pet-purple-text">
          <h5>Start Survey</h5>
          <div className="survey-go-btn">
            <div className="angle-right">^</div>
          </div>
        </a>

      </div>

    </div>

  </Layout>
)

export default FurrFitPage
